import Layout from "./Layout";

const TrackerLayout = () => {
  return (
    <div className="">
      <Layout />
    </div>
  );
};

export default TrackerLayout;
